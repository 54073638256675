import { Db } from "./db";

const db = new Db();

const countToBeOnFire = 3;

function countOnFire(mood, isReduce) {
	const fire = mood.querySelector(".on-fire");
	fire.textContent = "";

	const fireCount = (mood.dataset.clickcount || 0) - countToBeOnFire;
	if (fireCount > 0) {
		Array.from({ length: fireCount > 6 ? 6 : fireCount }, (x, i) => {
			fire.append("🔥");
		});
	}
}

const emojis = ["🤖", "👾", "🦄", "👋", "🦊", "🐶", "🐨", "🐼", "🐲", "🐙"];
let emojiCount = 0;

document.querySelector("#toggleTips").addEventListener("click", () => {
	document.querySelector(".tips").classList.toggle("show");
});

function resetMood(moodEl) {
	moodEl.classList.remove("selected");
	delete moodEl.dataset.clickcount;
	delete moodEl.dataset.emoji;
	delete moodEl.dataset.content;

	const fire = document.querySelector(".on-fire");
	if (fire) {
		fire.textContent = "";
	}
}

function selectMood(moodEl, isReduce = false, isReset = false) {
	moodEl.dataset.clickcount = isReduce
		? parseInt(moodEl.dataset.clickcount || 0) - 1
		: parseInt(moodEl.dataset.clickcount || 0) + 1;

	if (isReduce || isReset)
		db.undoMood(moodEl.dataset.moodcolor, moodEl.dataset.moodname);

	if (isReset || moodEl.dataset.clickcount <= 0) {
		resetMood(moodEl);
		return;
	}

	if (!isReduce)
		db.saveMood(moodEl.dataset.moodcolor, moodEl.dataset.moodname);

	countOnFire(moodEl, isReduce);

	if (moodEl.classList.contains("selected")) {
		moodEl.dataset.content = `
			${moodEl.dataset.emoji}
			${moodEl.dataset.clickcount > 1 ? ` + ${moodEl.dataset.clickcount - 1}` : ""}
		`;
		return;
	}

	moodEl.classList.add("selected");
	moodEl.dataset.emoji = emojis[emojiCount];
	emojiCount = emojiCount + 1 >= emojis.length ? Math.floor(Math.random() * emojis.length) : emojiCount + 1;

	moodEl.dataset.content = moodEl.dataset.emoji;
}

function setQuadrant(keyValue = null) {
	if (keyValue) {
		pressedQuadrant = keyValue;
		(document.querySelector(".mood-chart") as HTMLTableSectionElement).dataset.highlight = pressedQuadrant;
		return;
	}

	pressedQuadrant = null;
	delete (document.querySelector(".mood-chart") as HTMLTableSectionElement).dataset.highlight;
}

function mountMoodMeter() {
	const moods = {
		red: [
			"Enraged",
			"Panicked",
			"Stressed",
			"Jittery",
			"Shocked",
			"Livid",
			"Furious",
			"Frustrated",
			"Tense",
			"Stunned",
			"Fuming",
			"Frightened",
			"Angry",
			"Nervous",
			"Restless",
			"Anxious",
			"Apprehensive",
			"Worried",
			"Irritated",
			"Annoyed",
			"Repulsed",
			"Troubled",
			"Concerned",
			"Uneasy",
			"Peeved",
		],
		yellow: [
			"Surprised",
			"Upbeat",
			"Festive",
			"Exhilarated",
			"Ecstatic",
			"Hyper",
			"Cheerful",
			"Motivated",
			"Inspired",
			"Elated",
			"Energized",
			"Lively",
			"Enthusiastic",
			"Optimistic",
			"Excited",
			"Pleased",
			"Happy",
			"Focused",
			"Proud",
			"Thrilled",
			"Pleasant",
			"Joyful",
			"Hopeful",
			"Playful",
			"Blissful",
		],
		blue: [
			"Disgusted",
			"Glum",
			"Disappointed",
			"Down",
			"Apathetic",
			"Pessimistic",
			"Morose",
			"Discouraged",
			"Sad",
			"Bored",
			"Alienated",
			"Miserable",
			"Lonely",
			"Disheartened",
			"Tired",
			"Despondent",
			"Depressed",
			"Sullen",
			"Exhausted",
			"Fatigued",
			"Despair",
			"Hopeless",
			"Desolate",
			"Spent",
			"Drained",
		],
		green: [
			"At Ease",
			"Easygoing",
			"Content",
			"Loving",
			"Fulfilled",
			"Calm",
			"Secure",
			"Satisfied",
			"Grateful",
			"Touched",
			"Relaxed",
			"Chill",
			"Restful",
			"Blessed",
			"Balanced",
			"Mellow",
			"Thoughtful",
			"Peaceful",
			"Comfy",
			"Carefree",
			"Sleepy",
			"Complacent",
			"Tranquil",
			"Cozy",
			"Serene",
		],
	};

	Object.keys(moods).forEach((color) => {
		const quadrant = document.createElement("div");
		quadrant.classList.add("mood-quadrant");

		moods[color].forEach((mood, index) => {
			const moodBtn = document.createElement("button");
			moodBtn.classList.add("mood", color);
			moodBtn.dataset.tip = `${color[0].toUpperCase()}${index + 1}`;
			moodBtn.dataset.moodcolor = color;
			moodBtn.dataset.moodname = mood;
			moodBtn.textContent = mood;
			moodBtn.addEventListener("click", (e) => {
				const moodEl = e.composedPath().find((el) => (el as Element).tagName === "BUTTON");
				selectMood(moodEl, e.shiftKey);
			});
			moodBtn.addEventListener("keydown", (e) => {
				if (e.key === "Delete") {
					resetMood(e.target);
				}

				if (e.key === "Backspace" || e.key === "-") {
					selectMood(e.target, true);
				}
			});

			// Add the on-fire element to the current mood
			const fire = document.createElement("span");
			fire.classList.add("on-fire");
			moodBtn.append(fire);

			quadrant.append(moodBtn);
		});

		document.querySelector(".mood-chart").append(quadrant);
	});
}

let pressedQuadrant = null;
let pressedNumber = null;
let timeoutId = null;
let setToReduce = false;

document.body.addEventListener("keydown", (e) => {
	if (e.ctrlKey) {
		document.querySelector(".mood-chart").classList.add("show-tip");
	}

	if (["R", "Y", "B", "G"].includes(e.key.toUpperCase())) {
		setQuadrant(e.key.toUpperCase());
	}

	if (pressedQuadrant && e.key === "-") {
		setToReduce = true;
	}

	if (pressedQuadrant && parseInt(e.key) <= 9) {
		pressedNumber = `${pressedNumber || ""}${e.key}`;

		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			const mood = document.querySelector(`[data-tip=${pressedQuadrant}${pressedNumber}]`);
			if (setToReduce) {
				selectMood(mood, true);
				setToReduce = false;
			} else {
				selectMood(mood);
			}

			pressedNumber = null;
			setQuadrant();
		}, 300);

		e.preventDefault();
	}

	if (pressedQuadrant && e.key === "Escape") {
		timeoutId = null;
		pressedNumber = null;
		setToReduce = false;
		setQuadrant();
	}
});

document.body.addEventListener("keyup", (e) => {
	if (e.key === "Control") {
		document.querySelector(".mood-chart").classList.remove("show-tip");

		if (!timeoutId) {
			pressedNumber = null;
			setQuadrant();
		}
	}
});

mountMoodMeter();
